//
// Only common JS goes here, this will run on all pages
//
import 'reset-scss'
import 'intersection-observer'

import AOS from 'aos'
import 'aos/dist/aos.css';

import '../scss/main.scss'

import './navbar.js'
import './home.js'
import './about.js'
import './partners.js'

// import { TweenLite } from 'gsap'
//
// TweenLite.set('.fade-up', { y: 100, autoAlpha: 0 })
//
// const fadeObserver = new IntersectionObserver(entries => {
//   entries
//     .filter(e => e.intersectionRatio > 0)
//     .forEach(e => {
//       console.debug(e.intersectionRatio)
//       const target = e.target
//       TweenLite.to(target, 1, {y: 0, autoAlpha: 1})
//       fadeObserver.unobserve(target)
//     })
// })
//
// const fadeSections = document.querySelectorAll('.fade-up')
// fadeSections.forEach(section => {
//   fadeObserver.observe(section)
// })

AOS.init({
  once: true
});
