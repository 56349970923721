import $ from 'jquery'

$('.filter-btn').on('click', function(e) {
  e.preventDefault();
  // Remove active class on all filter-btn's
  $('.filter-btn').each(function() {
    $(this).removeClass('active');
  });
  // Add active class to the one clicked
  $(this).addClass('active');
  // Get filter data-attribute
  var filter = $(this).data('filterby');

  $('.filter-card').each(function() {
    // Set all cards to be display none
    $(this).removeClass('is-shown');
    if ($(this).data('filterby') == filter) {
      // Display if matches the filter
      $(this).addClass('is-shown');
    }
    if (filter == 'All') {
      // If filter is 'All' display all cards
      $(this).addClass('is-shown');
    }
  });
});
