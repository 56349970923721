import { TimelineMax, TweenLite } from 'gsap'
import $ from 'jquery'

if($('#about').length > 0) {
  const iconTimeline = new TimelineMax({paused: true})
  TweenLite.set('.goals-icon-container .icon', {backfaceVisibility: 'hidden'})
  iconTimeline.staggerFrom('.goals-icon-container .icon', .4, { rotationY: '+=180'}, .05);

  const iconObserver = new IntersectionObserver(element => {
    if(element[0].intersectionRatio > 0) {
      iconTimeline.play()
      iconObserver.unobserve(element[0].target)
    }
  })

  iconObserver.observe(document.querySelector('.goals-icon-container'));
}
