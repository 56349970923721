import $ from 'jquery'
import stickybits from 'stickybits'

$('#navToggler').on('click', function(e) {
  $('#navToggler').toggleClass('open');
  $('.navbar-inner').toggleClass('open');
  $('body').toggleClass('no-scroll');
});

stickybits('.navbar')
