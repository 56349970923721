import { TimelineMax } from 'gsap'
import $ from 'jquery'

// Action plan cycler stuff
// const $cycle_interactive = $('#interactive');
const $cycle = $('.ap-cycle');
const $cycle_btn_next = $('.ap-cycle-next');
const $cycle_btn_prev = $('.ap-cycle-prev');

const getCurrentCyclePanel = () => parseInt($cycle.data('current-panel'));
const fadeOutActiveCyclePanel = () => {
  $('.ap-cycle-copy-panel--active').removeClass('ap-cycle-copy-panel--active').fadeOut();
}
const fadeInCurrentPanel = () => {
  const panelIdx = getCurrentCyclePanel();
  $(`.ap-cycle-copy-panel[data-panel=${panelIdx}]`).addClass('ap-cycle-copy-panel--active').fadeIn();
}
const activateCurrentBeacon = () => {
  const panelIdx = getCurrentCyclePanel();
  $('#interactive .halo.active, #interactive .dot.active, #interactive2 .halo.active, #interactive2 .dot.active').removeClass('active');
  $(`#interactive .halo[data-panel=${panelIdx}], #interactive .dot[data-panel=${panelIdx}], #interactive2 .halo[data-panel=${panelIdx}], #interactive2 .dot[data-panel=${panelIdx}]`).addClass('active');
}
const gotoPanel = panelIdx => {
  if(panelIdx >= 1 && panelIdx <= 6) {
    $cycle.data('current-panel', panelIdx)
    fadeOutActiveCyclePanel();
    fadeInCurrentPanel();
    activateCurrentBeacon();

    if(panelIdx === 1) {
      $cycle_btn_prev.attr('disabled', 'disabled');
    } else {
      $cycle_btn_prev.attr('disabled', null);
    }
    if(panelIdx === 6) {
      $cycle_btn_next.attr('disabled', 'disabled');
    } else {
      $cycle_btn_next.attr('disabled', null);
    }
  }
}

$cycle_btn_next.on('click', () => {
  let currentIdx = getCurrentCyclePanel();
  if(currentIdx < 6) {
    currentIdx++;
    gotoPanel(currentIdx);
  }
});

$cycle_btn_prev.on('click', () => {
  let currentIdx = getCurrentCyclePanel();
  if(currentIdx > 1) {
    currentIdx--;
    gotoPanel(currentIdx);
  }
});

$('#interactive .dot, #interactive .halo').on('click', event => {
  const $dot = $(event.currentTarget);
  const panelIdx = parseInt($dot.data('panel'))
  if(panelIdx) {
    gotoPanel(panelIdx);
  }
})

const haloTl = new TimelineMax({repeat: -1, repeatDelay: 1});
haloTl.set('#home .halo', {
  transformOrigin: '50% 50%',
  x: 35,
  y: 35
});
haloTl.fromTo('#home .halo', 2, {
  autoAlpha: .7,
  scale: 0
}, {
  autoAlpha: 0,
  scale: 1
});
